import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <p><img parentName="p" {...{
        "src": "../docs/introduction@4x.png",
        "alt": null
      }}></img></p>
    <p>{`Welcome to Claromentis Design System and brand styleguide. Please use resources here to express Claromentis' brand in product and marketing channels.`}</p>
    <h2 {...{
      "id": "purpose",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#purpose",
        "aria-label": "purpose permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Purpose`}</h2>
    <p>{`The principles & guidelines outlined here are designed to support key customer journey stages. They are intended for everyone who is involved in these stages.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Customer Journey Stage`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1️⃣`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Awareness`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Eye-catching branding to help Claromentis stand out from the crowd`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2️⃣`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Consideration`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Help to effectively showcase Claromentis`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3️⃣`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Decision`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Remove hurdles and facilitate a smooth order process`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4️⃣`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Delivery & Onboarding`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Facilitate effective transition to being a customer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5️⃣`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Retention, Loyalty & Experience`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Long-term user experience & evolution`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6️⃣`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Advocacy`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Help to facilitate our customers to be advocates for our product and brand`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#features",
        "aria-label": "features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h2>
    <ul>
      <li parentName="ul">{`🖌 Simple and clean visual style;`}</li>
      <li parentName="ul">{`🎑 Focused on letting customer content stand out;`}</li>
      <li parentName="ul">{`📱 Supports desktop and mobile devices;`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`This site can be added to mobile device home screen as a PWA`}</p>
    </blockquote>
    <br />
    <p><a parentName="p" {...{
        "href": "/style-guide/getting-started"
      }}>{`Get started now!`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      